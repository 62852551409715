import { extractPageDataForSEO } from '~/helpers/dataHelpers'
import { graphql } from 'gatsby'
import CallToAction from '~/components/CallToAction'
import PageWrapper from '~/components/PageWrapper'
import QuoteWithImage from '~/components/QuoteWithImage'
import React from 'react'
import SEO from '~/components/SEO'

export default function IndexPage({ location, data }) {
  const { contentfulPage } = data
  return (
    <PageWrapper
      path={location.pathname}
      modules={data.contentfulPage?.moduleContents}
      bottomModules={data.contentfulPage?.bottomModules}
      hideBottomCta
    >
      <SEO
        {...extractPageDataForSEO(data.contentfulPage)}
        pathname={location.pathname}
      />
      <QuoteWithImage />
      {contentfulPage?.bottomModules && <CallToAction />}
    </PageWrapper>
  )
}

export const homePageQuery = graphql`
  query HomePageQuery {
    contentfulPage(name: { eq: "Home" }) {
      ...basicPageInfo
    }
  }
`
