import { Box, Flex, Heading, Icon, Text } from '@hometime-ui/core'
import { FaQuoteLeft } from 'react-icons/fa'
import { MARKETING_CONTACT_US_LINK } from '~/helpers/constants'
import { MdArrowForward } from 'react-icons/md'
import Image from './Image'
import React from 'react'
import useScreen from '~/hooks/useScreen'
import WidthWrapper from './WidthWrapper'

export default function QuoteWithImage() {
  const { isMobile, isTablet, isDesktop } = useScreen()

  return (
    <Box bg="white" py={{ base: 10, md: 20 }}>
      <WidthWrapper
        d="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={{ base: 'column', lg: 'row-reverse' }}
        w="100%"
      >
        <Flex
          flexDirection="column"
          alignItems={{ base: 'center', lg: 'start' }}
          justifyContent={{ lg: 'center' }}
          w={{ base: 'full', lg: '53%' }}
          className="fadeIn"
        >
          <Heading
            level={isMobile ? 3 : 2}
            textAlign={{ base: 'center', lg: 'left' }}
            mb={{ base: 6, lg: 8 }}
          >
            Lina doubled her business with Hometime
          </Heading>
          <Box
            bg="gray.200"
            px="8"
            py="6"
            color="gray.700"
            borderRadius="md"
            mb={{ base: 6, lg: 8 }}
            position="relative"
            maxWidth="630px"
          >
            {isDesktop && (
              <Box top="15px" left="-15px" position="absolute">
                <Icon color="gray.400" h="8" w="8" as={FaQuoteLeft} />
              </Box>
            )}
            <Text fontSize="lg">
              {
                "Hometime's software and services have helped me streamline my business operations and deliver more value to my clients. As a result, my business has doubled and strengthened its leading position in Darwin."
              }
            </Text>
            <Text fontSize="sm" mt="6" fontWeight="700">
              — Lina Paselli-Kruse
            </Text>
          </Box>
          <Text
            fontWeight="bold"
            color="teal.200"
            as="a"
            href={MARKETING_CONTACT_US_LINK}
            role="group"
          >
            {"Get started now. It's free"}
            <Icon
              as={MdArrowForward}
              color="teal.200"
              ml="1"
              transition="all 100ms"
              _groupHover={{ ml: '2' }}
            />
          </Text>
        </Flex>
        <Box
          h={{ base: '461px', lg: '520px', xl: '620px' }}
          w={{ base: 'full', lg: '53%' }}
          pl={{ lg: '20' }}
          position="relative"
          mt={{ base: 10, lg: 0 }}
          pr={{ base: 0, lg: 10 }}
          className="Bottom"
        >
          <Image
            style={{
              objectPosition: isMobile || isTablet ? 'center' : 'left center',
            }}
            relativePath="quote-image.png"
            containInHeight
          />
        </Box>
      </WidthWrapper>
    </Box>
  )
}
